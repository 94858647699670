import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  description: {
    textAlign: 'center',
    opacity: 0.8,
  }
});

function LoginForm(props) {
  const { classes } = props;
  const { t } = useTranslation('common');

  return (
    <div>
      <Typography variant="body1" className={classes.description}>
        {t('promo.product_description')}
      </Typography>
    </div>
  )
}


LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginForm)
export { LoginForm }